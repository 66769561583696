import styled from "styled-components"

import { spacing } from "src/ui/spacing"

const PageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${spacing.XL};
`

export const RouteNotFound = () => {
  return (
    <PageBox>
      <h1>404 Page not found</h1>
    </PageBox>
  )
}
